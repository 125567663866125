<template>
	<div class="home">
		<a-layout class="wrap">
			
			<!-- 顶部区域 -->
			<a-layout-header class="header f-acjsb pl-15 pr-15">
				<div class="logo" >
					<!-- sdsd -->
					<!-- <img src="../assets/sdyj.png" alt=""> -->
				</div>
				<template v-if="role == 'admin'">
					<!-- 管理员权限 -->
					<div class="account f-ac">
						    <a-select  :default-value="defaultid" style="width: 200px" @change="handleshop">
						      <a-select-option :value="item.id" v-for="(item,index) in allshop">
						        账号：{{item.name}}
						      </a-select-option>
						    </a-select>
						<a-button class="ml-15" type="danger" @click="logout">
						  退出登录
						</a-button>
					</div>
				</template>
				<template v-else>
					<div class="account f-ac">
						<div style="color: #fff;margin-right: 15px;">
							账号：{{shopname}}
						</div>
						
						<a-button type="danger" @click="logout">
						  退出登录
						</a-button>
					</div>
				</template>
				

			</a-layout-header>
			
			<a-layout>
				<!-- 左侧内容 -->
				<a-layout-sider class="layoutsider"  collapsible v-model="collapsed" :trigger="null" >
					<a-menu mode="inline" class="meun"  
					:default-open-keys="defopen"   
					v-model:default-selected-keys="defselect"
					:openKeys="openKeys"
					 @openChange="onOpenChange"
					 @click="handleClick"
					>
						<template v-for="item in sidemenu">
							<a-menu-item  v-if="item.sub.length<=0" :key="item.key" >
								<a-icon :type="item.icon" />
								<span>{{item.name}}</span>
							</a-menu-item>
							<a-sub-menu v-else :key="item.key">
								<span slot="title"><a-icon :type="item.icon" /><span>{{item.name}}</span></span>
								<a-menu-item v-for="items in item.sub" :key="items.key" >
									<span>{{items.name}}</span>
								</a-menu-item>
							</a-sub-menu>
						</template>
					</a-menu>
					<!--展开和收缩按钮 -->
					<div class="controlbtn">
						<a-icon class="trigger" :type="collapsed ? 'menu-unfold' : 'menu-fold'" 
						@click="() => (collapsed = !collapsed)" />
					</div>
				</a-layout-sider>
				<!-- 右侧内容 -->
				<a-layout class="layoutright mt-30">
					<!-- 面包屑导航 -->
					<!-- <a-breadcrumb class="breadcrumb">
						<a-breadcrumb-item>Home</a-breadcrumb-item>
						<a-breadcrumb-item>List</a-breadcrumb-item>
						<a-breadcrumb-item>App</a-breadcrumb-item>
					</a-breadcrumb> -->
					<!-- 页面内容 -->
					<a-layout-content class="layout-content">
						<router-view></router-view>
					</a-layout-content>
				</a-layout>
			</a-layout>
		</a-layout>
	</div>

</template>

<script>
	// @ is an alias to /src
	import {
		mapState,
		// mapGetters,
		// mapMutations,
		mapActions
	} from "vuex";
	export default {
		name: 'Home',
		data() {
			return {
				collapsed: false,//菜单展开收缩
				sidemenu:[
					{name:"运营数据",icon:"bank",sub:[],key:"dashboard"},
					{name:"小程序配置",icon:"tablet",sub:[
						{name:"菜单导航",icon:"home",sub:[],key:"appletset_navmenu"},
						{name:"最近活动",icon:"home",sub:[],key:"appletset_activity"},
						{name:"推荐",icon:"home",sub:[],key:"appletset_recommend"},
						{name:"应急上门",icon:"home",sub:[],key:"appletset_emergency"},
						{name:"分类管理",icon:"home",sub:[],key:"appletset_classify"},
						{name:"单位管理",icon:"home",sub:[],key:"appletset_unit"},
					],key:"appletset"},
					{name:"分店配置",icon:"setting",sub:[
						{name:"小店管理",icon:"home",sub:[],key:"branchshop_storeinfo"},
						{name:"大店管理",icon:"home",sub:[],key:"branchshop_bigshop"}
					],key:"branchshop"},
					// {name:"个人中心管理",icon:"user",sub:[],key:"profile"},
					// {name:"营销活动",icon:"tags",sub:[],key:"marketing"},
					{name:"客服会话系统",icon:"message",sub:[],key:"kefu"},
					{name:"商品管理",icon:"medicine-box",sub:[
						{name:"商品列表",icon:"home",sub:[],key:"goods_list"},
						// {name:"修改商品",icon:"home",sub:[],key:"goods_modify"},
					],key:"goods"},
					{name:"订单管理",icon:"reconciliation",sub:[
						{name:"订单列表",icon:"home",sub:[],key:"order_orderlist"},
					],key:"order"},
					{name:"用户管理",icon:"control",sub:[
						{name:"用户列表",icon:"control",sub:[],key:"user_userlist"},
					],key:"user"},
					{name:"资源管理器",icon:"picture",sub:[],key:"resources"},
					
				],
				defopen:['dashboard'],//默认展开
				defselect:['dashboard'],//默认选中
		
			    // 全部顶级父节点,用来控制所有父级菜单只展开其中的一项，可用遍历菜单信息进行赋值
			    rootSubmenuKeys: ['dashboard','appletset','branchshop','profile','marketing','goods','order','user','resources'],
			    // 展开的父菜单项
			    openKeys: ['dashboard'], 
			}
		},
		computed: {
			// 映射数据，方法到this对象上
			...mapState({
				page: (state) => state.index.page,
				login: (state) => state.login.login,
			}),
			// 角色
			role(){
				let login = JSON.parse(window.localStorage.getItem('login'))
				if(login){
					return login.role
				}
			},
			// 店铺名称
			shopname(){
				let login = JSON.parse(window.localStorage.getItem('login'))
				if(login){
					return login.name
				}
			},
			// oa链接
			oaurl(){
				let login = JSON.parse(window.localStorage.getItem('login'))
				if(login){
					return login.oa_url
				}
			},
			// 管理员相关数据
			allshop(){
				let login = JSON.parse(window.localStorage.getItem('login'))
				if(login){
					return login.shopAll
				}
			},
			defaultid(){
				// shopid
				let login = JSON.parse(window.localStorage.getItem('login'))
				let shopid = JSON.parse(window.localStorage.getItem('shopid'))
				if(shopid){
					return shopid.toString()
				}else{
					if(login){
						return login.shopid
					}
				}
				
			}
		},
		watch: {
			    $route:{
			      handler(val,oldval){
					this.defselect[0] = val.name
					this.defopen[0] = val.path.substring(0,val.path.indexOf('/'))+"/"+val.path.substring(0,val.path.indexOf('/'))
					let openkey = window.sessionStorage.getItem('openKeys')
					if(openkey){
						this.openKeys[0] = window.sessionStorage.getItem('openKeys')
					}
			      },
			      // 深度观察监听
			      deep: true
			    }
		},
		created() {
			// this.getindex()
			// 获取缓存默认菜单
			let acttivekey = window.sessionStorage.getItem("acttivekey")
			if(acttivekey){
				this.$router.push({name:acttivekey})
				this.defselect[0] = acttivekey
				this.defopen[0] = acttivekey.substring(0,acttivekey.indexOf('/'))+"/"+acttivekey.substring(0,acttivekey.indexOf('/'))
				let openkey = window.sessionStorage.getItem('openKeys')	
				if(openkey){
					this.openKeys[0] = window.sessionStorage.getItem('openKeys')
				}
				  // console.log("取值",this.defopen);
			}else{
				// 默认打开页面
				this.$router.push({name:this.defselect[0]})
			}
			// 增加oa菜单
			if(this.oaurl){
				this.sidemenu.push({name:"OA系统",icon:"desktop",sub:[],key:"oa"})
			}
		},
		methods: {
			...mapActions({
				getlogout:"login/getlogout",
				getswitchshop:"login/getswitchshop"
			}),
			// 切换店铺
			async handleshop(e){
				console.log(e,"切换店铺");
				let msg = await this.getswitchshop(e)
				this.$message.success(msg);
				window.localStorage.setItem('shopid',e)
				// 刷新页面
				location.reload()
			},
			handleClick(e){
				// 获取当前点击的菜单key值push到默认选中
				if(e.key == "kefu"){
					let login = JSON.parse(window.localStorage.getItem('login'))
					
					console.log(login,"登录信息")
					// console.log(this.$route);
					window.open(login.service_url)
				}else if(e.key == "oa"){
					let login = JSON.parse(window.localStorage.getItem('login'))
					// login.oa_url
					window.open(login.oa_url)
				}else{
					this.$router.push({name:e.key})
					window.sessionStorage.setItem("acttivekey",e.key)
				}
			},
			onOpenChange(openKeys) {
				console.log(openKeys,"传入参数");
			 
			  // //  控制只打开一个
			  const latestOpenKey = openKeys.find(key => this.openKeys.indexOf(key) === -1);
			  console.log(latestOpenKey,"最后打开是");
			  // 将当前打开的父级菜单存入缓存中
			 
				window.sessionStorage.setItem('openKeys', latestOpenKey)
				if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
				  this.openKeys = openKeys;
				} else {
				  this.openKeys = latestOpenKey ? [latestOpenKey] : [];
				}  
			  
			},
			// logout
			async logout(){
				let msg = await this.getlogout()
				this.$message.success(msg);
				// 清除登录缓存
				 window.sessionStorage.clear();
				 window.localStorage.clear();
				// 重新进入登录页面
				this.$router.push({name:'login_login'})
			}
		},
	}

</script>

<style lang="less" >
	.home {
		position: fixed;
		inset: 0;
		overflow: hidden;
		width: 100%;
		height: 100%;
	}
	.wrap{
		height: 100%;
		.header{
			padding: 0;
		}
	}
	.wrap .logo {
		width: 120px;
		height: 31px;
		background: rgba(255, 255, 255, 0.2);
		margin: 16px 28px 16px 0;
		float: left;
		background: url(../assets/sdyj.png) no-repeat center;
		background-size: 78% 108%;
	}
	.layoutsider{
		width: 200px;
		background-color: #fff;
		height: 100%;
		position: relative;
		.meun{
			height: 100%;
			border-right: 0px;
		}
		.controlbtn{
			width: 100%;
			position: absolute;
			bottom: 0;
			height: 40px;
			text-align: center;
			line-height: 40px;
		}
	}
	.layoutright{
		padding: 0 24px 24px;
		.breadcrumb{
			margin: 16px 0
		}
		.layout-content{
			background: #fff; 
			padding: 24px; 
			margin: 0;
			flex: none;
			min-width: 960px;
		}
	}
</style>
